<template>
  <v-container fluid>
    <v-row style="height: 100%;">
      <v-col col=12 md=6 class="pa-0 ma-0">
        <v-img
          alt="haq.ai"
          class="overflow-hidden"
          contain
          width="100%"
          height="100%"
         :src="require('@/assets/images/auth.svg')"
          transition="scale-transition"
        />

      </v-col>
      <v-col>
        <v-container class="">
          <v-toolbar-title class="">
            <v-img
              alt="haq.ai"
              class="shrink"
              contain
              :src="theme ? require('@/assets/logowhite.svg') : require('@/assets/logo.svg')"
              transition="scale-transition"
              width="200px"
              height="50.6px"
              @click="$router.push('/')"
            />
          </v-toolbar-title>
          <router-view class="mt-10"/>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import colorMxn from '@/mixins/color_mixin';

export default {
  name: 'Auth',
  mixins: [colorMxn],
  data() {
    return {
    }
  }
}
</script>

<style>
  .banner-light {
   background: #6034BD80;
  }

  .banner-dark {
   background: #C1AFE97D;
  }
</style>